<template>
  <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      submit="Submit"
      button-class="bg-dynamicBackBtn text-white"
      @submit="handleSubmit(onSubmit)"
      :disabled-button="isFetching"
      :loading="isFetching"
      width="width:520px"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg">Get Leave Back</span>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col justify-start items-start gap-4 pb-4">
          <p class="text-darkPurple text-base font-normal mb-0">Create a leave back request.</p>

          <div class="w-full flex flex-col justify-start items-start gap-4">
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Approved Leave</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">Pensioner Leave, 10 Days (30 Jan, 2023 - 09 Feb, 2023)</span>
              </div>
            </div>
            <div class="w-full text-darkPurple flex flex-col gap-2">
              <span class="font-normal text-sm">Leave Back Days</span>
              <div class="border border-romanSilver rounded-md">
                <datepicker
                  placeholder="--Select--"
                  input-class="date-input"
                  style="width:100%"
                  :required="true"
                  :rules="['required']"
                  :disabled-dates="disabledDates"
                  v-model="days"
                />
              </div>
            </div>
          </div>

          <div class="w-full flex flex-col items-end gap-7">
            <div @click="days && (leaveBackDays.push({ days, justification: '' }), days = null)">
              <div class="hover:bg-cultured p-2 rounded-md">
                <div class="flex flex-row justify-start items-center gap-2 cursor-pointer">
                  <c-icon icon-name="icon-plus" class="text-flame" size="xs" />
                  <span class="text-base text-flame font-normal">Add New</span>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start gap-5">
              <div v-for="(item, i) in leaveBackDays" :key="JSON.stringify(item)">
                <div class="flex flex-row justify-between items-center gap-2 relative">
                  <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
                    <span class="text-sm text-jet font-normal">Days</span>
                    <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                      <span class="text-romanSilver text-base font-normal">{{ $DATEFORMAT(item.days, 'dd MMM, yyyy') }}</span>
                    </div>
                  </div>
                  <c-select
                    v-model="item.justification"
                    :options="justificationOptions"
                    label="Justification"
                    class="select-class w-full"
                    :rules="['required']"
                  />
                  <div v-if="leaveBackDays.length > 1" class="mt-5 cursor-pointer" @click="leaveBackDays.splice(i, 1)">
                    <c-icon icon-name="close" class="text-flame" size="xs" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
            <span class="text-sm text-jet font-normal">Total Leave Back Days</span>
            <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
              <span class="text-romanSilver text-base font-normal">{{ leaveBackDays.length }} Day(s)</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <c-button width="126px" class="text-dynamicBackBtn border border-solid border-dynamicBackBtn">Save as Draft</c-button>
      </template>
    </RightSideBar>
  </validation-observer>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { ValidationObserver } from "vee-validate"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import RightSideBar from "@/components/RightSideBar"
import CButton from "@/components/Button"
import CIcon from "@/components/Icon"

export default {
components: {
    Datepicker,
    ValidationObserver,
    RightSideBar,
    CIcon,
    CSelect,
    CButton,
  },
  computed: {
    disabledDates() {
      return {
        customPredictor: date => (
          !(date >= new Date('30 Jan, 2023') && date <= new Date('09 Feb, 2023'))
        )
      };
    }
  },
  data(){
    return {
      isOpen: false,
      isFetching: false,
      leaveBackDays: [],
      days: null,
      justificationOptions: [
        'Public Holidays',
        'Call back to work'
      ],

      payload: {}
    }
  },
  methods: {
    onOpen(){
      this.isOpen = true
    },
    onSubmit(){},
  },
  created(){}

}
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
</style>